// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bk_kz";
export var caseStudyBackground__lineColor = "bk_kq";
export var caseStudyHead = "bk_kC";
export var caseStudyHead__imageWrapper = "bk_kn";
export var caseStudyProjectsSection = "bk_kB";
export var caseStudyQuote__bgLight = "bk_kx";
export var caseStudyQuote__bgRing = "bk_kw";
export var caseStudyVideo = "bk_kD";
export var caseStudyVideo__ring = "bk_kF";
export var caseStudy__bgDark = "bk_km";
export var caseStudy__bgLight = "bk_kl";